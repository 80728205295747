export const EVENT_TYPE = Object.freeze({
  KEYDOWN: 'keydown',
  KEYPRESS: 'keypress',
  KEYUP: 'keyup',
  CLICK: 'click',
  ENTER: 'Enter',
  FOCUS: 'focus',
  INPUT: 'input',
  SUBMIT: 'submit',
  CHANGE: 'change'
});